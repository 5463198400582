import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RouteData, UserEntity } from '@omedom/data';
import { RoutingService, UserService } from '@omedom/services';
import { Observable } from 'rxjs';

import { navigation } from './app.routes';

@Component({
    selector: 'pro-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    /**
     * @description Navigation data (title, icon, path, index, type) for sidenav and header
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/09/2023
     * @type {RouteData[]}
     * @memberof AppComponent
     */
    private navigation: RouteData[] = navigation;

    /**
     * @description Title of the page (displayed in toolbar) (default: undefined) (optional)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/09/2023
     * @type {string}
     * @memberof AppComponent
     */
    public title?: string;

    public titleFontSize: number = 36;

    /**
     * @description Icon name of the page (displayed in toolbar) (default: undefined) (optional)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/09/2023
     * @type {string}
     * @memberof AppComponent
     */
    public icon?: string;

    /**
     * @description True if the icon is an omedom icon, false otherwise (default: false) (optional)
     * @author Killian Brisset
     * @date 09/08/2024
     * @type {boolean}
     * @memberof AppComponent
     */
    public isOmedomIcon: boolean = false;

    /**
     * @description User data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/09/2023
     * @type {(Observable<UserEntity | undefined>)}
     * @memberof AppComponent
     */
    public user$: Observable<UserEntity | undefined> = this.userService.user$;

    /**
     * @description Enable back button in toolbar (default: false) (optional) (used in toolbar components)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/09/2023
     * @type {boolean}
     * @memberof AppComponent
     */
    public enableBack: boolean = false;

    constructor(
        private router: Router,
        private userService: UserService,
        private routingService: RoutingService
    ) {
        // Set title and icon on route change
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const path = this.router.url.split('?')[0];
                const routeData = this.getRouteDataByPath(path);

                if (routeData) {
                    this.title = routeData.title;
                    this.icon = routeData.icon;
                    this.isOmedomIcon = routeData.isOmedomIcon || false;
                } else {
                    this.title = undefined;
                    this.icon = undefined;
                }
            }
        });

        this.routingService.enableBack.subscribe((enableBack) => {
            this.enableBack = enableBack;
        });

        this.routingService.title.subscribe((title) => {
            if (title) {
                this.title = title;
            }
        });

        this.routingService.titleFontSize.subscribe((titleFontSize) => {
            if (titleFontSize) {
                this.titleFontSize = titleFontSize;
            }
        });

        this.routingService.icon.subscribe((icon) => {
            if (icon) {
                this.icon = icon;
            }
        });

        this.routingService.isOmedomIcon.subscribe((isOmedomIcon) => {
            if (isOmedomIcon) {
                this.isOmedomIcon = isOmedomIcon;
            }
        });

        // Check if user is logged in
        this.userService.user$.subscribe((user) => {
            if (!user) {
                this.router.navigate(['/user/login']);
            }
        });
    }

    /**
     * @description Get navigation data (title, icon, path, index, type) for sidenav and header by type (primary or secondary) (used in sidenav and header components)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/09/2023
     * @param {('primary' | 'secondary')} type
     * @returns {RouteData[]}
     * @memberof AppComponent
     */
    public getNavigation(type: 'primary' | 'secondary'): RouteData[] {
        return this.navigation.filter((route) => route.type === type);
    }

    /**
     * @description Get navigation data (title, icon, path, index, type) for toolbar by path (used in toolbar components) (optional) (used in toolbar components)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/09/2023
     * @private
     * @param {string} path
     * @returns {(RouteData | undefined)}
     * @memberof AppComponent
     */
    private getRouteDataByPath(path: string): RouteData | undefined {
        return this.navigation.find((route) => route.path === path);
    }

    public logout(): void {
        this.userService.logout();
    }
}
