<ion-app>
    @if (user$ | async) {
    <omedom-sidenav>
        <img
            logo
            src="/assets/icon/white-logo.svg"
            alt="Logo OMEDOM"
            routerLink="/"
            title="Logo OMEDOM"
            style="width: 100% !important; height: 100px !important"
        />
        <!-- Navigation Primary -->
        @for (navigation of getNavigation('primary'); track navigation.path) {
        <omedom-sidenav-item
            nav-primary
            [routerLink]="navigation.path"
            routerLinkActive="active"
            [icon]="navigation.icon"
            [label]="navigation.title"
            [isOmedomIcon]="navigation.isOmedomIcon ?? false"
        ></omedom-sidenav-item>
        }
        <!-- Navigation Secondary -->
        @for (navigation of getNavigation('secondary'); track navigation.path) {
        <omedom-sidenav-item
            nav-secondary
            [routerLink]="navigation.path"
            routerLinkActive="active"
            [icon]="navigation.icon"
            [label]="navigation.title"
            [isOmedomIcon]="navigation.isOmedomIcon ?? false"
        ></omedom-sidenav-item>
        }
        <omedom-sidenav-item
            nav-secondary
            (click)="logout()"
            icon="signout"
            label="Se déconnecter"
        ></omedom-sidenav-item>
    </omedom-sidenav>
    }

    <main>
        @if (user$ | async) {
        <omedom-toolbar
            [enableBack]="enableBack"
            [title]="title"
            [titleFontSize]="titleFontSize"
            [icon]="icon"
            [isOmedomIcon]="isOmedomIcon"
        >
            <button
                toolbar-action
                disabled
                class="omedom-icon-button-secondary-outline"
            >
                <i class="uil uil-bell"></i>
            </button>
        </omedom-toolbar>
        }

        <div class="container">
            <router-outlet></router-outlet>
        </div>
    </main>
</ion-app>
