import { Component, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ProAlertEntity, RouteData, UserEntity } from '@omedom/data';
import {
    ChatMessageService,
    ChatService,
    ProAlertService,
    RoutingService,
    UserService,
} from '@omedom/services';
import { Observable, of, switchMap } from 'rxjs';

import { navigation } from './app.routes';
import { listAnimation, OmedomSubTab } from '@omedom/ui';
import { IonModal, ModalController } from '@ionic/angular';

@Component({
    selector: 'pro-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [listAnimation],
})
export class AppComponent {
    /**
     * @description Navigation data (title, icon, path, index, type) for sidenav and header
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/09/2023
     * @type {RouteData[]}
     * @memberof AppComponent
     */
    private navigation: RouteData[] = navigation;

    /**
     * @description Title of the page (displayed in toolbar) (default: undefined) (optional)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/09/2023
     * @type {string}
     * @memberof AppComponent
     */
    public title?: string;

    public titleFontSize: number = 36;

    /**
     * @description Icon name of the page (displayed in toolbar) (default: undefined) (optional)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/09/2023
     * @type {string}
     * @memberof AppComponent
     */
    public icon?: string;

    /**
     * @description Alerts of the pro
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 07/10/2024
     * @type {Observable<ProAlertEntity[]>}
     * @memberof AppComponent
     */
    public proAlerts$: Observable<ProAlertEntity[]> = of([]);

    @ViewChild('startChatModal') startChatModal?: IonModal;

    public proAlertSubTab: OmedomSubTab[] = [
        {
            label: 'Tous',
            id: 'all',
        },
        {
            label: 'Traités',
            id: 'treated',
        },
        {
            label: 'Non traités',
            id: 'untreated',
        },
    ];

    public selectedProAlertSubTab: OmedomSubTab = this.proAlertSubTab[0];

    /**
     * @description True if the icon is an omedom icon, false otherwise (default: false) (optional)
     * @author Killian Brisset
     * @date 09/08/2024
     * @type {boolean}
     * @memberof AppComponent
     */
    public isOmedomIcon: boolean = false;

    /**
     * @description User data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/09/2023
     * @type {(Observable<UserEntity | undefined>)}
     * @memberof AppComponent
     */
    public user$: Observable<UserEntity | undefined> = this.userService.user$;

    /**
     * @description Enable back button in toolbar (default: false) (optional) (used in toolbar components)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/09/2023
     * @type {boolean}
     * @memberof AppComponent
     */
    public enableBack: boolean = false;

    constructor(
        private router: Router,
        private userService: UserService,
        private routingService: RoutingService,
        private proAlertService: ProAlertService,
        private chatService: ChatService,
        private chatMessageService: ChatMessageService,
        private readonly modalController: ModalController
    ) {
        // Set title and icon on route change
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const path = this.router.url.split('?')[0];
                const routeData = this.getRouteDataByPath(path);

                if (routeData) {
                    this.title = routeData.title;
                    this.icon = routeData.icon;
                    this.isOmedomIcon = routeData.isOmedomIcon || false;
                } else {
                    this.title = undefined;
                    this.icon = undefined;
                }
            }
        });

        // Get alerts of the pro
        this.proAlerts$ = this.user$.pipe(
            switchMap((user) => {
                if (!user) {
                    return of([]);
                }
                return this.proAlertService._getUserAlerts(user.uid);
            })
        );

        this.routingService.enableBack.subscribe((enableBack) => {
            this.enableBack = enableBack;
        });

        this.routingService.title.subscribe((title) => {
            if (title) {
                this.title = title;
            }
        });

        this.routingService.titleFontSize.subscribe((titleFontSize) => {
            if (titleFontSize) {
                this.titleFontSize = titleFontSize;
            }
        });

        this.routingService.icon.subscribe((icon) => {
            if (icon) {
                this.icon = icon;
            }
        });

        this.routingService.isOmedomIcon.subscribe((isOmedomIcon) => {
            if (isOmedomIcon) {
                this.isOmedomIcon = isOmedomIcon;
            }
        });

        // Check if user is logged in
        this.userService.user$.subscribe((user) => {
            if (!user) {
                this.router.navigate(['/user/login']);
            }
        });
    }

    public getAlertsBySubTab(proAlerts: ProAlertEntity[]): ProAlertEntity[] {
        const sortedProAlerts = proAlerts.sort((a, b) => {
            return (a.created as any).seconds - (b.created as any).seconds;
        });
        switch (this.selectedProAlertSubTab.id) {
            case 'all':
                return sortedProAlerts;
            case 'treated':
                return sortedProAlerts.filter((proAlert) => proAlert.isTreated);
            case 'untreated':
                return sortedProAlerts.filter((proAlert) => !proAlert.isTreated);
            default:
                return sortedProAlerts;
        }
    }

    /**
     * @description Get navigation data (title, icon, path, index, type) for sidenav and header by type (primary or secondary) (used in sidenav and header components)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/09/2023
     * @param {('primary' | 'secondary')} type
     * @returns {RouteData[]}
     * @memberof AppComponent
     */
    public getNavigation(type: 'primary' | 'secondary'): RouteData[] {
        return this.navigation.filter((route) => route.type === type);
    }

    /**
     * @description Get navigation data (title, icon, path, index, type) for toolbar by path (used in toolbar components) (optional) (used in toolbar components)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/09/2023
     * @private
     * @param {string} path
     * @returns {(RouteData | undefined)}
     * @memberof AppComponent
     */
    private getRouteDataByPath(path: string): RouteData | undefined {
        return this.navigation.find((route) => route.path === path);
    }

    protected async createChatWithClient(proAlert: ProAlertEntity) {
        try {
            await this.startChatModal?.present();

            await this.startChatModal?.onDidDismiss().then(async (res) => {
                const data = res.data;

                const chatName = data?.chatName;

                const message = data?.message;

                const treat = data?.treat;

                if (!chatName || !message) {
                    return;
                }

                const chatRef = await this.chatService.create({
                    userUIDs: [proAlert.userUID, proAlert.clientUID],
                    creatorUID: proAlert.userUID,
                    name: chatName,
                });

                await this.chatMessageService.create({
                    chatUID: chatRef.id,
                    userUID: proAlert.userUID,
                    content: message,
                });

                if (treat) {
                    await this.proAlertService.update({
                        uid: proAlert.uid,
                        isTreated: true,
                    });
                }

                this.modalController?.getTop().then((modal) => {
                    modal?.dismiss();
                });
                await this.router.navigate(['/messaging'], {
                    queryParams: { chatUID: chatRef.id },
                });
            });
        } catch (error) {
            console.error(error);
        }
    }

    public logout(): void {
        this.userService.logout();
    }
}
